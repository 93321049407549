import axios from 'axios';

const BASE_URL = 'https://jd.vto.homes/api';
// const BASE_URL = 'http://localhost:5000';

export const fetchRequest = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const getListings = async (pageNumber, resultsPerPage, Queries) => {
  const url = `${BASE_URL}/listings?pageNumber=${pageNumber}&resultsPerPage=${resultsPerPage}${Queries}`;
  return await fetchRequest(url);
};

export const getMapListings = async (polygon) => {
  const url = `${BASE_URL}/map?polygon=${polygon}`;
  return await fetchRequest(url);
}

export const getSingleListing = async (id) => {
  const url = `${BASE_URL}/listings/${id}`;
  return await fetchRequest(url);
};
export const getSimilarListings = async (id) => {
  const url = `${BASE_URL}/similar/${id}`;
  return await fetchRequest(url);
};

export const getSearchListings = async (search) => {
  const url = `${BASE_URL}/listings/search?search=${search}`;
  return await fetchRequest(url);
};
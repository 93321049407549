import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import home1 from '../assets/images/home 1.jpg';
import home2 from '../assets/images/home 2.jpg';
import home3 from '../assets/images/home-3.jpg';
import { GrLocation } from "react-icons/gr";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { motion } from 'framer-motion';
import { BiArea } from "react-icons/bi";
import { getListings } from '../service';
import LoaderCard from './loader';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';


const ListingProperties = () => {
    const list = [
        {
            price: '$7899999',
            address: '148-37 88th Ave,Torronto 11435',
            beds: '4',
            baths: '3',
            sqft: '60 x 100 sqft',
            image: home1,
            name: 'Archers Insight'
        },
        {
            price: '$9450000',
            address: '148-37 88th Ave,Torronto 11435',
            beds: '5',
            baths: '4',
            sqft: '77 x 100 sqft',
            image: home2,
            name: 'Oakwood Villa'
        },
        {
            price: '$5200000',
            address: '148-37 88th Ave,Torronto 11435',
            beds: '3',
            baths: '2',
            sqft: '53 x 100 sqft',
            image: home3,
            name: 'Maple Heights'
        },

    ];
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const navigate = useNavigate();
    useEffect(() => {
        getListingsData();
    }, [itemsPerPage]);
    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth <= 1280) {
                setItemsPerPage(3);
            } else {
                setItemsPerPage(4);
            }
        });
    }, []);

    const getListingsData = async () => {
        
        const filterQuery = '&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000';
        try {
            const result = await getListings(1, itemsPerPage ? itemsPerPage : 4, filterQuery);
            console.log(result.data.listings.length);
            setListings(result.data.listings);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const formatPrice = (price) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(price);
    };



    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
        >
            <div className='xl:px-5 px-20 mt-4 max-sm:px-2'>
                <h1 className=' max-sm:text-2xl text-[45px] text-center'>Discover Our Featured Listings</h1>
                <div className='mt-1'>
                    <div className='grid grid-cols-4 gap-7 p-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl:grid-cols-3 xl:pt-16 xl2:grid-cols-4 xl2:pt-10  max-lg:grid-cols-2 2xl:grid-cols-4 max-xl:grid-cols-3 place-items-center'>
                        {loading ? (
                            Array.from({ length: itemsPerPage ? itemsPerPage : 4 }).map((_, index) => (
                                <LoaderCard key={index} />
                            ))
                        ) : (
                            listings.map((item, index) => (
                                <div key={index} className='w-[370px] max-2xl:w-[330px]  max-sm:w-full rounded-xl shadow-lg'
                                    onClick={() => navigate(`/property/${item.mlsNumber}`)}>
                                    <div className='h-[500px] max-2xl:h-[400px] relative bg-cover bg-center rounded-2xl overflow-hidden'>
                                        <img
                                            src={item.images[0]}
                                            alt='home'
                                            className='rounded-2xl w-full h-full object-cover transform hover:scale-105'
                                            style={{
                                                transition: 'all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease',
                                            }}
                                        />
                                        <div className='p-4 rounded-b-xl absolute top-[64%] w-full'>
                                            <div className='w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm'>
                                                <div className='flex justify-start items-center'>
                                                    <h2 className='text-lg text-[#006938] font-bold whitespace-nowrap'>{formatPrice(item.listPrice)}</h2>
                                                </div>
                                                <div className='flex gap-1 items-center'>
                                                    <GrLocation size={22} className="shrink-0"/>
                                                    <h2 className='text-sm overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap' data-tooltip-id="my-tooltip" data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}>{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                                                </div>
                                                <div className='flex items-center justify-between'>
                                                    <div className='flex items-center gap-3'>
                                                        <div className='flex gap-1 items-center'>
                                                            <IoBedOutline size={25} />
                                                            <h4>{item.details.numBedrooms ? item.details.numBedrooms : '0'}</h4>
                                                        </div>
                                                        <span className='text-gray-300'>|</span>
                                                        <div className='flex gap-1 items-center'>
                                                            <LiaBathSolid size={25} />
                                                            <h4>{item.details.numBathrooms ? item.details.numBathrooms : '0'}</h4>
                                                        </div>
                                                        <span className='text-gray-300'>|</span>
                                                        <div className='flex gap-1 items-center'>
                                                            <BiArea size={25} />
                                                            <h4>{item.lot.width ? `${item.lot.depth} x ${item.lot.width}` : '0 x 0'}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}

                    </div>
                    <Tooltip id="my-tooltip" />
                </div>
            </div>
        </motion.div>
    );
};

export default ListingProperties;


// import React from 'react';

// const PropertyCard = ({ property }) => {
//     return (
//         <div className="property-card">
//             <img src={property.image} alt={property.title} className="property-image" />
//             <div className="property-details">
//                 <h3 className="property-title">{property.title}</h3>
//                 <p className="property-location">{property.location}</p>
//                 <p className="property-price">${property.price}</p>
//                 <p className="property-description">{property.description}</p>
//                 <button className="view-details">View Details</button>
//             </div>
//         </div>
//     );
// };

// export default PropertyCard
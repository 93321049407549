import React, { useState, useEffect } from 'react';
import CustomDropdown from '../components/CustomDropdown';
import { CiSearch,CiMail } from "react-icons/ci";
import agent_1Img from '../assets/images/agent-2.png';
import agent_2Img from '../assets/images/agent-1.jpg';
import agent_3Img from '../assets/images/agent-3.jpg';
import agent_4Img from '../assets/images/agent-4.webp';
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { motion } from "framer-motion";



const AgentListPage = () => {
  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [language, setLanguage] = useState('');
  const [position, setPosition] = useState('');
  const [designation, setDesignation] = useState('');
  const [office, setOffice] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    const fetchedAgents = [
      {
        name: 'Loren Ackerman',
        role: 'Sales Representative',
        location: 'Toronto, ON',
        email: 'john@example.com',
        phone: '123-456-7890',
        image: agent_1Img,
        language: 'English',
        position: 'Agent',
        designation: 'ABR - Accredited Buyers Representative',
        profileLink: '/profile/john-doe',
        office: 'J&D Toronto'
      },
      {
        name: 'Chip & Virginia Aikenhead',
        role: 'Sales Representative',
        location: 'Vancouver, BC',
        email: 'jane@example.com',
        phone: '098-765-4321',
        image: agent_2Img,
        language: 'French',
        position: 'Expert',
        designation: 'CRA - Canadian Residential Appraiser',
        profileLink: '/profile/jane-smith',
        office: 'J&D Oakville'

      },
      {
        name: 'Aryeh Altman',
        role: 'Broker',
        location: 'Toronto, ON',
        email: 'john@example.com',
        phone: '123-456-7890',
        image: agent_3Img,
        language: 'English',
        position: 'Agent',
        designation: 'SRS - Seller Representative Specialistct',
        profileLink: '/profile/john-doe',
        office: 'J&D Muskoka'
      },
      {
        name: 'Jane Smith',
        role: 'Luxury Property Expert',
        location: 'Vancouver, BC',
        email: 'jane@example.com',
        phone: '098-765-4321',
        image: agent_4Img,
        language: 'French',
        position: 'Expert',
        designation: 'MCNE - Master Certified Negotiation',
        profileLink: '/profile/jane-smith',
        office: 'J&D Kawarthas'
      },
      {
        name: 'Morgan Andresen',
        role: 'Sales Representative',
        location: 'Toronto, ON',
        email: 'john@example.com',
        phone: '123-456-7890',
        image: agent_1Img,
        language: 'English',
        position: 'Agent',
        designation: 'ABR - Accredited Buyers Representative',
        profileLink: '/profile/john-doe',
        office: 'J&D Toronto'
      },
      {
        name: 'Allison Andrus',
        role: 'Sales Representative',
        location: 'Vancouver, BC',
        email: 'jane@example.com',
        phone: '098-765-4321',
        image: agent_2Img,
        language: 'French',
        position: 'Expert',
        designation: 'CRA - Canadian Residential Appraiser',
        profileLink: '/profile/jane-smith',
        office: 'J&D Oakville'

      },
      {
        name: 'Heather Fuller',
        role: 'Broker',
        location: 'Toronto, ON',
        email: 'john@example.com',
        phone: '123-456-7890',
        image: agent_3Img,
        language: 'English',
        position: 'Agent',
        designation: 'SRS - Seller Representative Specialistct',
        profileLink: '/profile/john-doe',
        office: 'J&D Muskoka'
      },
      {
        name: 'Andrew Hall',
        role: 'Broker',
        location: 'Vancouver, BC',
        email: 'jane@example.com',
        phone: '098-765-4321',
        image: agent_4Img,
        language: 'French',
        position: 'Expert',
        designation: 'MCNE - Master Certified Negotiation',
        profileLink: '/profile/jane-smith',
        office: 'J&D Kawarthas'
      },
      // Add more agent objects here...
    ];
    setAgents(fetchedAgents);
    setFilteredAgents(fetchedAgents); 
  }, []);

  const handleFilter = (query) => {
    setSearchTerm(query);
    const result = agents.filter(agent =>
      agent.name.toLowerCase().includes(query.toLowerCase()) ||
      agent.location.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredAgents(result);
  };
 
  const handleSearch = () => {
    // Handle the search functionality
    console.log(`Searching for term: ${searchTerm}, Language: ${language},`);
  };
  
  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    if(selectedLanguage === 'All') {
      setFilteredAgents(agents);
      return;
    }
   let filtered = agents;
    if (selectedLanguage) {
      filtered = filtered.filter(agent => agent.language === selectedLanguage);
    }
    setFilteredAgents(filtered);
  };


  const handlePositionChange = (selectedPosition) => {
    setPosition(selectedPosition);
    if(selectedPosition === 'All') {
      setFilteredAgents(agents);
      return;
    }
    let filtered = agents;
    if (selectedPosition) {
      filtered = filtered.filter(agent => agent.position === selectedPosition);
    }
    setFilteredAgents(filtered);
  }
  const handleDesignationChange = (selectedDesignation) => {
    setDesignation(selectedDesignation);
    if(selectedDesignation === 'All') {
      setFilteredAgents(agents);
      return;
    }
    let filtered = agents;
    if (selectedDesignation) {
      filtered = filtered.filter(agent => agent.designation === selectedDesignation);
    }
    setFilteredAgents(filtered);
  };
  const handleOfficeChange = (selectedOffice) => {
    setOffice(selectedOffice);
    if(selectedOffice === 'All') {
      setFilteredAgents(agents);
      return;
    }
    let filtered = agents;
    if (selectedOffice) {
      filtered = filtered.filter(agent => agent.office === selectedOffice);
    }
    setFilteredAgents(filtered);
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};

const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
};

const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
            <button
                key={i}
                className={`w-12 rounded-full text-sm ${i === currentPage ? 'bg-[#545350] text-white' : 'bg-gray-200'}`}
                onClick={() => handlePageClick(i)}
            >
                {i}
            </button>
        );
    }

    return pageNumbers;
};

  return (
    <div className="container mx-auto p-4 pt-20 px-32 max-2xl:px-24 font-roboto">
      {/* Page Heading */}
      <div className="text-center my-8">
        <h1 className="text-4xl font-bold text-gray-900">Find a Realtor</h1>
        <p className="text-lg text-gray-600 mt-2">Find the perfect agent to help with your real estate needs.</p>
      </div>

      {/* Search & Filter */}
      <div className="bg-white p-4 shadow-md rounded-lg flex items-center space-x-4 w-full  justify-around mx-auto">
      {/* Language Dropdown */}
      <div className="p-2">
      <CustomDropdown
        label="LANGUAGE"
        options={['All','English', 'French', 'Spanish', 'French', 'Italian', 'Portugese', 'German', 'Russian']}
        value={language}
        onChange={handleLanguageChange}
      />
    </div>

      {/* Region Dropdown */}
      <div className="p-2">
      <CustomDropdown
        label="POSITION"
        options={['All','Broker', 'Agent', 'Expert', 'Consultant', 'Advisor', 'Associate', 'Administrator', 'Admin']}
        value={position}
        onChange={handlePositionChange}
      />
    </div>
    {/* Office Dropdown */}
    <div className="p-2">
      <CustomDropdown
        label="DESIGNATION"
        height={350}
        options={['All','ABR - Accredited Buyers Representative', 'CBR - Certified Buyer Representative', 'CNE - Certified Negotiation Expert', 'CRB - Certified Real Estate Brokerage', 'Manager', 'GREEN', 'Institute for Luxury Home Marketing', 'SRES - Seniors Real Estate Specialist',
          'SRS - Seller Representative Specialistct', 'MCNE - Master Certified Negotiation', 'CLHMS', 'CRA - Canadian Residential Appraiser', 'ASA - Accredited Senior Agent', 'Luxury Listing Specialist', 'ARIDO', 'IDC', 'LUXE Luxury Listing Specialist'
        ]}
        value={designation}
        onChange={handleDesignationChange}
      />
    </div>
    <div className="p-2">
      <CustomDropdown
        label="OFFICE"
        options={['All','J&D Toronto', 'J&D Kawarthas', 'J&D Oakville', 'J&D Muskoka']}
        value={office}
        onChange={handleOfficeChange}
      />
    </div>

      {/* Search Box */}
      <div className="flex flex-col items-start">
        <label className="text-gray-600 font-medium">SEARCH</label>
        <div className="flex items-center border-b border-gray-300">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => handleFilter(e.target.value)}
            placeholder="search by agent name"
            className="w-full py-2 focus:outline-none"
          />
          <span className="text-gray-500 ml-2">
            <CiSearch size={24}/>
          </span>
        </div>
      </div>

      {/* Search Button */}
      {/* <button
        onClick={handleSearch}
        className="bg-Stone-500 text-white px-6 py-2 rounded-md text-lg hover:bg-Stone-600"
      >
        Search
      </button> */}
    </div>

      {/* Agent Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
    {filteredAgents.map((agent, index) => (
      <motion.div
        key={index}
        className="relative w-full h-80 perspective flip-card"
      >
        {/* Card Inner */}
        <div className="flip-card-inner">
          {/* Front Side */}
          <div className="flip-card-front bg-white shadow-lg rounded-lg flex flex-col items-center justify-center">
            <div className="w-full h-60 overflow-hidden">
              <img
                src={agent.image}
                alt={agent.name}
                className="object-cover w-full h-full"
              />
            </div>
            <div className="p-4 text-start w-full">
              <h3 className="text-xl font-bold text-gray-900">{agent.name}</h3>
              <p className="text-sm text-gray-500">{agent.role}</p>
            </div>
          </div>

          {/* Back Side */}
          <div className="flip-card-back bg-white shadow-lg rounded-lg p-6 text-center">
            <p className="text-sm text-gray-500">{agent.office}</p>
            <div className="mt-4 flex flex-col items-center space-y-2">
              <div className="flex items-center gap-2">
                <CiMail size={20} color="#78716c" />
                <span className="text-gray-500">{agent.email}</span>
              </div>
              <div className="flex items-center gap-2">
                <IoCallOutline size={20} color="#78716c" />
                <a
                  href={`tel:${agent.phone}`}
                  className="text-gray-500 hover:underline"
                >
                  {agent.phone}
                </a>
              </div>
            </div>
            <div className="mt-4">
              <a
                href={agent.profileLink}
                className="bg-gray-500 text-white text-sm px-3 py-1 rounded-full hover:bg-gray-600 transition duration-300"
              >
                View Profile
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    ))}
  </div>

  { totalPages > 1 && (
    <div className="flex gap-5 justify-center mt-8">
    <button
        className="bg-[#545350] p-4 rounded-full disabled:opacity-50"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
    >
        <FaArrowLeft color='#ffffff' />
    </button>
    {renderPageNumbers()}
    <button
        className="bg-[#545350] p-4 rounded-full disabled:opacity-50"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
    >
        <FaArrowRight color='#ffffff' />
    </button>
</div>
  )}
      
    </div>
  );
};

export default AgentListPage;

import React, { useEffect } from 'react';
import HeaderComponent from '../components/Header';
import Footer from '../components/footer';
import { Outlet, useLocation } from 'react-router-dom'; // Import Outlet

const Layout = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="layout">
      <HeaderComponent />
      <div className="content">
        <Outlet /> {/* This is where routed components will be rendered */}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

import React, { useState, useRef, useEffect } from 'react';
import { FaAngleDown } from "react-icons/fa6";


const CustomDropdown = ({ options, value, onChange, label,height }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-start" ref={dropdownRef}>
      <label className="text-gray-600 font-medium">{label}</label>
      <div className="relative">
        <button
          type="button"
          className="border-b whitespace-nowrap border-gray-300 focus:border-Stone-500 focus:outline-none py-2 pr-4 w-52 text-left truncate"
          onClick={handleToggle}
        >
          {value || 'Select'}
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
            <FaAngleDown />
          </span>
        </button>
        {isOpen && (
          <ul className={`${height ? `h-[${height}px]` : 'h-auto'} absolute z-10 w-96 overflow-auto  bg-white border border-gray-300 mt-1 rounded-md shadow-lg`}>
            {options.map((option) => (
              <li
                key={option}
                className="py-2 px-4 hover:bg-Stone-500 hover:text-white cursor-pointer text-start"
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
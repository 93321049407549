import React, { useContext, useState } from 'react';
import ImageSlider from '../components/imageSlider';
import { FilterContext } from '../components/FilterContext';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { IoClose } from "react-icons/io5";


const Modal = ({ isOpen, onClose, value, index, title,onSubmit }) => {
  const {
    propertyType,
    setPropertyType,
    bedrooms,
    setBedrooms,
    bathrooms,
    setBathrooms,
    parking,
    setParking,
    price,
    setPrice,
    sqft,
    setSqft,
    status,
    setStatus,
    maintenanceFee,
    setMaintenanceFee
  } = useContext(FilterContext);
  const [property, setProperty] = useState('All Property Types');
  const handleRangeChange = (value) => {
    setPrice(value);
  };
  const handleSqftChange = (value) => {
    setSqft(value);
  }

  const propertyTypes = ['All Property Types',
    "Detached",
    "Semi-Detached",
    "Condo Apt",
    "Condo Townhouse",
    "Duplex",
    "Multiplex",
    "Vacant Land",
    "Farm"];
    const propertiesOption = [
      { id: 1, name: 'All Property Types', key: 'All' },
      { id: 2, name: 'Detached', key: 'Detached' },
      { id: 3, name: 'Semi-Detached', key: 'Semi-Detached' },
      { id: 4, name: 'Condo Apt', key: 'Condo Apt' },
      { id: 5, name: 'Condo Townhouse', key: 'Condo townhouse' },
      { id: 6, name: 'Duplex', key: 'duplex' },
      { id: 7, name: 'Multiplex', key: 'multiplex' },
      { id: 8, name: 'Vacant Land', key: 'vacant land' },
      { id: 9, name: 'Farm', key: 'farm' }
  ];

  if (!isOpen) return null;

  const clearFilters = () => {
    setPropertyType('');
    setPrice([0, 3200000]);
    setBedrooms(null);
    setBathrooms(null);
  };
  const selectPropertyType = (item) => {
    propertiesOption.map((property) => {
        if (property.id === item.id) {
            setProperty(property.name);
            setPropertyType(property.key);
        }
    });
};
  const handleSubmit = () => {
    onSubmit('true');
    onClose()
};

  return (
    <>
      {title === 'filter' && (
  <div className="fixed inset-0 z-[2000] flex items-center justify-center bg-black bg-opacity-50 px-4">
    <div className="bg-white rounded-lg shadow-lg max-h-[90vh] w-full max-w-3xl relative">
      {/* Header */}
      <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
        <h2 className="text-2xl font-semibold text-gray-800">Filters</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition duration-150"
        >
          <IoClose size={24} />
        </button>
      </div>

      {/* Scrollable Content */}
      <div className="overflow-y-auto px-6 py-4 space-y-6 max-h-[65vh]">
        {/* Property Types */}
        <div>
          <h3 className="font-semibold text-gray-700 mb-2">Property Type</h3>
          <div className="grid grid-cols-3 gap-3">
            {propertiesOption.map(type => (
              <button
                key={type.id}
                className={`p-2 border rounded-md transition duration-150 ${
                  property === type.name ? 'bg-Stone-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                }`}
                // onClick={() => setPropertyType(prev =>
                //   prev.includes(type) ? prev.filter(item => item !== type) : [...prev, type]
                // )}
                onClick={() => selectPropertyType(type)}
              >
                {type.name}
              </button>
            ))}
          </div>
        </div>

        {/* Bedrooms, Bathrooms, Parking */}
        <div className="grid grid-cols-1 items-center gap-6">
          <div>
            <h3 className="font-semibold text-gray-700 mb-2">Bedrooms</h3>
            <div className="flex space-x-2 justify-center">
              {[1, 2, 3, 4, 5].map(num => (
                <button
                  key={num}
                  className={`p-3 border rounded-full w-12 transition duration-150 ${
                    bedrooms === num ? 'bg-Stone-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                  onClick={() => setBedrooms(num)}
                >
                  {num}+
                </button>
              ))}
            </div>
          </div>

          <div>
            <h3 className="font-semibold text-gray-700 mb-2">Bathrooms</h3>
            <div className="flex space-x-2 justify-center">
              {[1, 2, 3, 4, 5].map(num => (
                <button
                  key={num}
                  className={`p-3 border rounded-full w-12 transition duration-150 ${
                    bathrooms === num ? 'bg-Stone-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                  onClick={() => setBathrooms(num)}
                >
                  {num}+
                </button>
              ))}
            </div>
          </div>

          <div>
            <h3 className="font-semibold text-gray-700 mb-2">Parking</h3>
            <div className="flex space-x-2 justify-center">
              {[1, 2, 3, 4, 5].map(num => (
                <button
                  key={num}
                  className={`p-3 border rounded-full w-12 transition duration-150 ${
                    parking === num ? 'bg-Stone-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                  onClick={() => setParking(num)}
                >
                  {num}+
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Price Range */}
        <div>
          <h3 className="font-semibold text-gray-700 mb-2">Price</h3>
          <Slider
            range
            min={0}
            max={3200000}
            value={price}
            onChange={handleRangeChange}
            className="w-full h-2 mb-4 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between text-gray-600">
            <span>${price[0].toLocaleString()}</span>
            <span>${price[1].toLocaleString()}</span>
          </div>
        </div>

        {/* Square Footage */}
        <div>
          <h3 className="font-semibold text-gray-700 mb-2">Square Footage (sqft)</h3>
          <Slider
            range
            min={0}
            max={8000}
            value={sqft}
            onChange={handleSqftChange}
            className="w-full h-2 mb-4 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between text-gray-600">
            <span>{sqft[0]} sqft</span>
            <span>{sqft[1]}+ sqft</span>
          </div>
        </div>

      </div>

      {/* Fixed Footer */}
      <div className="flex justify-between items-center p-4 border-t border-gray-200">
        <button
          className="px-4 py-2 bg-Stone-500 text-white rounded-md hover:bg-Stone-600 transition duration-150"
          onClick={handleSubmit}
        >
          Apply Filter
        </button>
        <button
          className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 transition duration-150"
          onClick={clearFilters}
        >
          Clear All
        </button>
      </div>
    </div>
  </div>
)}



      {title === 'imageDialog' && (
        <div className="fixed inset-0 overflow-hidden z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-Stone-600 overflow-auto  rounded-lg w-full h-full px-5 relative flex flex-col">
            <div className="flex-grow">
              <ImageSlider images={value} initialIndex={index} />
            </div>
            <button
              onClick={onClose}
              className="absolute top-3 right-4 bg-Indigo-700/40 p-3 font-bold text-white py-2 px-4 rounded"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
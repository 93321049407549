import React, { useEffect, useState } from "react";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoShareOutline } from "react-icons/io5";
import { IoPrintOutline } from "react-icons/io5";
import DialogModal from "../components/photosDialog";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { IoHammerOutline } from "react-icons/io5";
import { GiHomeGarage } from "react-icons/gi";
import { useParams } from 'react-router-dom';
import { getSingleListing, getSimilarListings } from "../service";
import SinglePropertiesMap from "../components/SinglePropertiesMap";
import RequestTourInfo from "../components/dialog-components/requesttourInfo";
import { GrLocation } from "react-icons/gr";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { BiArea } from "react-icons/bi";
import { getListings } from '../service';
import LoaderCard from '../components/loader';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";



const PropertyView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const [singleListing, setSingleListing] = useState({});
  const [loading, setLoading] = useState(true);
  const [coordinates, setCoordinates] = useState({});
  const [isRequestTourOpen, setIsRequestTourOpen] = useState(false);
  const [title, setTitle] = useState('tour');
  const [featureListings, setFeatureListings] = useState([])
  const [similarListings, setSimilarListings] = useState([])
  const [similarlistingLoading, setSimilarListingLoading] = useState(false)
  const slider = React.useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);



  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: false,
    initialSlide: 0,
    afterChange: (index) => setCurrentIndex(index),
  };

  useEffect(() => {
    getSingleListingValue(id);
    getSimilarItems(id);
    getFeatureListings();
  }, [id]);

  const getSingleListingValue = async (id) => {
    setLoading(true);
    try {
      const response = await getSingleListing(id);
      console.log('rep', response)
      setSingleListing(response.data.listing);
      const coordinates = {
        lat: response.data.listing.map.latitude,
        lng: response.data.listing.map.longitude,
      };
      setCoordinates(coordinates);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getFeatureListings = async () => {
    const filterQuery = '&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000';
    try {
      const result = await getListings(1, 4, filterQuery);
      setFeatureListings(result.data.listings);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getSimilarItems = async (id) => {
    try {
      setSimilarListingLoading(true)
      const result = await getSimilarListings(id);
      setSimilarListings(result.data.listing);
      console.log('similar', result);
    } catch (error) {
      console.error(error);
    } finally {
      setSimilarListingLoading(false)
    }
  };


  const handleShowAllPhotos = (index) => {
    setIndexValue(index);
    setIsModalOpen(true);
  };
  const handleRequestTour = (val) => {
    console.log('Request Tour');
    if (val === 'tour') {
      setTitle('tour');
    } else {
      setTitle('info');
    }
    setIsRequestTourOpen(true);
  }



  const formatDate = (dateString) => {
    console.log(dateString);
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate;
  }
  const closeModal = () => {
    setIsModalOpen(false);

  }

  const addressFormat = (item) => {
    return `${item.streetNumber} ${item.streetName} ${item.city}`
  };
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(price);
  };

  // if (loading) {
  //   return <div className="pt-32"> Loading...</div>
  // }
  return (

    <div>
      {loading ? (
        Array(5)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className={`${index === 0 ? 'col-span-2 row-span-2' : 'col-span-1'} 
                       bg-gray-300 animate-pulse rounded-lg h-48 w-full`}
            ></div>
          ))

      ) : (
        <div className="px-14 pt-24">
          <div className="flex gap-3 justify-end p-5">
            <div className="flex items-center gap-3">
              <div className="p-2 border rounded-full border-gray-300">
                <IoMdHeartEmpty />
              </div>
              <span>Save</span>
            </div>
            <div className="flex items-center gap-3">
              <div className="p-2 border rounded-full border-gray-300">
                <IoShareOutline />
              </div>
              <span>Share</span>
            </div>
            <div className="flex items-center gap-3">
              <div className="p-2 border rounded-full border-gray-300">
                <IoPrintOutline />
              </div>
              <span>Print</span>
            </div>

          </div>
          <div className="grid grid-cols-4 gap-4">

            {singleListing.images && singleListing.images.slice(0, 5).map((image, index) => (
              <div
                key={index}
                className={`${index === 0 ? 'col-span-2 row-span-2' : 'col-span-1'} relative overflow-hidden rounded-lg`}
                onClick={() => handleShowAllPhotos(index)}
              >
                <img
                  src={image}
                  alt={`image-${index}`}
                  className="object-cover w-full h-full"
                />
                {index === 4 && (
                  <div className="absolute inset-0 flex items-end justify-end p-5">
                    <button
                      onClick={() => handleShowAllPhotos(0)}
                      className="bg-black bg-opacity-50 text-white text-lg font-bold rounded-lg px-4 py-2"
                    >
                      Show All Photos
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <DialogModal isOpen={isModalOpen} title={'imageDialog'} onClose={closeModal} value={singleListing.images} index={indexValue} />

          <div className="flex flex-row mt-10  font-roboto gap-10">
            <div className="w-[70%]">
              <div className="flex flex-col w-full">
                {/* <div><h1 className="text-start text-3xl font-bold">{singleListing.office.brokerageName}</h1></div> */}
                <div className="flex justify-between">
                  <div className="flex gap-3 items-center text-2xl">
                    <span className=""><HiOutlineLocationMarker /></span>
                    <h1 className="font-bold">{singleListing.address ? addressFormat(singleListing.address) : ''}</h1>
                  </div>
                  <h1 className="text-2xl font-semibold">{singleListing.listPrice ? formatPrice(singleListing.listPrice) : ''}</h1>
                </div>
              </div>
              <div className="w-full grid-cols-3 grid gap-3 mt-8">
                <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                  <span><IoHomeOutline size={22} /></span>
                  <p>{singleListing?.details?.propertyType ? singleListing.details.propertyType : ''}</p>
                </div>
                <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                  <span><IoHammerOutline size={22} /></span>
                  <p>Build Year {singleListing.details.yearBuilt ? singleListing.details.yearBuilt : '-'}</p>
                </div>
                <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                  <span><BiArea size={22} /></span>
                  <p>{singleListing.lot ? `${singleListing.lot.depth} X ${singleListing.lot.width}` : `0 X 0`} Ft Lot</p>
                </div>
                <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                  <span><IoBedOutline size={22} /></span>
                  <p>{singleListing.details?.numBedrooms ? singleListing.details.numBedrooms : '0'} Bedrooms</p>
                </div>
                <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                  <span><LiaBathSolid size={22} /></span>
                  <p>{singleListing.details?.numBathrooms ? singleListing.details.numBathrooms : '0'} Bathrooms</p>
                </div>
                <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                  <span><GiHomeGarage size={22} /></span>
                  <p>{singleListing.details?.numGarageSpaces ? singleListing.details.numGarageSpaces : '0'} Garage</p>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Description</h1>
                <p className="text-gray-500 mt-6 text-base leading-8">{singleListing.details?.description}</p>
                {/* <p className="text-gray-500 mt-6 text-base leading-8">Enchanting three bedroom, three bath home with spacious one bedroom, one bath cabana, in-laws quarters.
                 Charming living area features fireplace and fabulous art deco details. Formal dining room.
               </p>
               <p className="text-gray-500 mt-4 text-base leading-8">Remodeled kitchen with granite countertops, white cabinetry and stainless appliances. Lovely master bedroom has updated bath, beautiful view of the pool. Guest bedrooms have walk-in, cedar closets. Delightful backyard; majestic oaks surround the free form pool and expansive patio, wet bar and grill.</p> */}
              </div>

              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Property Details</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">MLS No</p>
                    <p className="text-gray-500">{singleListing.mlsNumber}</p>
                  </div>

                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Property Style</p>
                    <p className="text-gray-500">{singleListing.details.style ? singleListing.details.style : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">List Price</p>
                    <p className="text-gray-500">{singleListing.listPrice ? `$ ${singleListing.listPrice}` : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Bedrooms</p>
                    <p className="text-gray-500">{singleListing.details?.numBathrooms ? singleListing.details?.numBathrooms : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Parking</p>
                    <p className="text-gray-500">{singleListing.details?.numBathrooms ? singleListing.details?.numBathrooms : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Garage</p>
                    <p className="text-gray-500">{singleListing.details?.garage ? singleListing.details.garage : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Build year</p>
                    <p className="text-gray-500">{singleListing.details.yearBuilt ? singleListing.details.yearBuilt : '-'} </p>
                  </div>
                  {singleListing.details.style === 'Apartment' ? (
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Sqft</p>
                      <p className="text-gray-500">{singleListing.details.sqft ? singleListing.details.sqft : `-`} Sqft</p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Lot Size</p>
                      <p className="text-gray-500">{singleListing.lot ? `${singleListing.lot.depth} X ${singleListing.lot.width}` : `-`} Ft Lot</p>
                    </div>
                  )}

                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Sewer</p>
                    <p className="text-gray-500">{singleListing.details?.sewer ? singleListing.details?.sewer : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Construction</p>
                    <p className="text-gray-500">{singleListing.details.exteriorConstruction1 ? singleListing.details.exteriorConstruction1 : '-'}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Address</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Street</p>
                    <p className="text-gray-500">{singleListing.address?.streetName ? singleListing.address?.streetName : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Zip/Postal Code</p>
                    <p className="text-gray-500">{singleListing.address?.zip ? singleListing.address?.zip : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">City</p>
                    <p className="text-gray-500">{singleListing.address?.city ? singleListing.address?.city : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Area</p>
                    <p className="text-gray-500">{singleListing.address?.area ? singleListing.address?.area : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">District</p>
                    <p className="text-gray-500">{singleListing.address?.district ? singleListing.address?.district : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">State</p>
                    <p className="text-gray-500">{singleListing.address?.state ? singleListing.address?.state : '-'}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold"> Listing Details</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Brokerage</p>
                    <p className="text-black font-normal">{singleListing.office?.brokerageName}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Data Source</p>
                    <p className="text-black font-normal">-</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Listed On</p>
                    <p className="text-black font-normal">{formatDate(singleListing.listDate)}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Updated On</p>
                    <p className="text-black font-normal">{formatDate(singleListing.timestamps?.listingUpdated)}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Interior Details</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Bedrooms</p>
                    <p className="text-black font-normal">{singleListing.details?.numBathrooms ? singleListing.details?.numBathrooms : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Bathrooms</p>
                    <p className="text-black font-normal">{singleListing.details?.numBathrooms ? singleListing.details?.numBathrooms : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Rooms</p>
                    <p className="text-black font-normal">{singleListing.details?.numRooms ? singleListing.details?.numRooms : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Kitchen</p>
                    <p className="text-black font-normal">{singleListing.details?.numKitchens ? singleListing.details?.numKitchens : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Bathroom Detail</p>
                    <p className="text-black font-normal">
                      {`${singleListing.details?.bathrooms[1].count} ${singleListing.details?.bathrooms[1].pieces} pc, ${singleListing.details?.bathrooms[1].level}`}
                    </p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Basement Type</p>
                    <p className="text-black font-normal">
                      Apartment</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Utilities Central</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Heating</p>
                    <p className="text-black font-normal">{singleListing.details?.heating ? singleListing.details?.heating : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Water</p>
                    <p className="text-black font-normal">{singleListing.details?.waterSource ? singleListing.details?.waterSource : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Cooling</p>
                    <p className="text-black font-normal">{singleListing.details.airConditioning ? singleListing.details.airConditioning : '-'}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Parking Details</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Driveway</p>
                    <p className="text-black font-normal">{singleListing.details?.driveway ? singleListing.details?.driveway : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Garage Type</p>
                    <p className="text-black font-normal">{singleListing.details?.garage ? singleListing.details?.garage : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Garage</p>
                    <p className="text-black font-normal">{singleListing.details?.numGarageSpaces ? singleListing.details?.numGarageSpaces : '-'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Parking Spaces</p>
                    <p className="text-black font-normal">{singleListing.details?.numParkingSpaces ? singleListing.details?.numParkingSpaces : '-'}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Other Details</h1>
                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Den</p>
                    <p className="text-black font-normal">{singleListing.details?.den === 'Y' ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Swimming Pool</p>
                    <p className="text-black font-normal">{singleListing.details?.swimmingPool === 'Y' ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Furnished</p>
                    <p className="text-black font-normal">{singleListing.details?.furnished === 'Y' ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                    <p className="font-medium">Family Rooms</p>
                    <p className="text-black font-normal">{singleListing.details?.familyRoom === 'Y' ? 'Yes' : 'No'}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10 text-start">
                <h1 className="text-xl font-semibold">Map View</h1>
                <div className="w-full rounded-lg mt-10 h-96  p-7">
                  <SinglePropertiesMap
                    coordinates={coordinates}
                  />
                </div>
              </div>

            </div>

            <div className="flex flex-col p-2 gap-4 w-[30%] h-full font-open-sans">
              <div className="flex flex-col gap-4 p-8 border border-gray-300 rounded-lg m-3">
                <p>Request a Tour as early as Today</p>
                <button className="bg-Amber-400/70 font-semibold text-black p-3 rounded-lg" onClick={() => handleRequestTour('tour')}>Request Tour</button>
                <button className="bg-white border-Amber-400 border text-black p-3 rounded-lg" onClick={() => handleRequestTour('info')}>Request Info</button>
              </div>

              {/* similar listings */}
              <div className="p-1 px-3 mt-3">
                <div className="flex justify-between items-center p-2">
                  <h1 className="text-lg font-semibold">Similar Listings</h1>
                  <div className="flex gap-3">
                    <div className={`cursor-pointer text-black ${currentIndex > 0 ? '' : 'opacity-40'}`}
                      onClick={() => currentIndex > 0 && slider.current.slickPrev()}>
                      <FaArrowLeft color='#00000 ' size={23} />
                    </div>
                    <div className={`cursor-pointer text-black hover:opacity-70 ${currentIndex < similarListings.length - 1 ? '' : 'opacity-40'}`}
                      onClick={() => currentIndex < similarListings.length - 1 && slider.current.slickNext()}>
                      <FaArrowRight color='#000000 ' size={23} />
                    </div>

                  </div>
                </div>
                {similarlistingLoading ? (
                  Array.from({ length: 1 }).map((_, index) => (
                    <LoaderCard key={index} />
                  ))
                ) : (
                  <div>
                    {similarListings === 0 ? (
                      <div>...Oops no similar data found </div>
                    ) : (
                      <>
                      <Slider {...sliderSettings} ref={slider}>
                        {similarListings.map((item, index) => (
                          <div key={index}>
                            <div className='h-[350px] cursor-pointer m-2 relative bg-cover bg-center rounded-2xl overflow-hidden'>
                              <img
                                src={item.images[0]}
                                alt='home'
                                className='rounded-2xl w-full h-full object-cover transform hover:scale-105'
                                style={{
                                  transition: 'all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease',
                                }}
                                onClick={() => window.open(`/property/${item.mlsNumber}`, '_blank')}
                              />
                              <div className='p-4 rounded-b-xl absolute top-[55%] w-full'>
                                <div className='w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm'>
                                  <div className='flex justify-start items-center'>
                                    <h2 className='text-lg text-[#006938] font-bold whitespace-nowrap'>{formatPrice(item.listPrice)}</h2>
                                  </div>
                                  <div className='flex gap-1 items-center'>
                                    <GrLocation size={20} className="shrink-0" />
                                    <h2 className='text-base max-2xl:text-sm  overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap' data-tooltip-id="my-tooltip" data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}>{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                                  </div>
                                  <div className='flex items-center justify-between'>
                                    <div className='flex items-center gap-2'>
                                      <div className='flex gap-1 items-center'>
                                        <IoBedOutline size={20} />
                                        <h4 data-tooltip-id="my-tooltip" data-tooltip-content={item.details.numBedrooms ? item.details.numBedrooms : 0}>{item.details.numBedrooms ? item.details.numBedrooms : 0}</h4>

                                      </div>
                                      <span className='text-gray-300'>|</span>
                                      <div className='flex gap-1 items-center'>
                                        <LiaBathSolid size={20} />
                                        <h4 data-tooltip-id="my-tooltip" data-tooltip-content={item.details.numBathrooms ? item.details.numBathrooms : 0}>{item.details.numBathrooms ? item.details.numBathrooms : 0}</h4>

                                      </div>
                                      <span className='text-gray-300'>|</span>
                                      <div className='flex gap-1 items-center'>
                                        <BiArea size={20} />
                                        <h4 className='truncate' data-tooltip-id="my-tooltip" data-tooltip-content={item.lot.width ? `${item.lot.depth} x ${item.lot.width}` : '0 x 0'}>{item.lot.width ? `${item.lot.depth} x ${item.lot.width}` : '0 x 0'}</h4>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ))}

                      </Slider>
                      </>
                    )}

                  </div>
                )
                }


              </div>
            </div>
          </div>

          {/* fetaure listings */}

          <div className="mt-10 mb-5">
            <h1 className="text-xl font-semibold text-start">Featured Listings</h1>
            <div className="flex justify-end items-center">
            <h3 className="text-lg text-end px-7 pr-8 hover:text-Stone-500 cursor-pointer"
              onClick={() => navigate('/buy')}>See More</h3>
            </div>
            
            <div className='mt-2'>
              <div className='grid grid-cols-4 gap-7 px-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl2:grid-cols-4  max-lg:grid-cols-2 2xl:grid-cols-4 place-items-center'>
                {loading ? (
                  Array.from({ length: 3 }).map((_, index) => (
                    <LoaderCard key={index} />
                  ))
                ) : (
                  featureListings.map((item, index) => (
                    <div key={index} className='w-full rounded-xl shadow-lg '
                      onClick={() => window.open(`/property/${item.mlsNumber}`, '_blank')}>
                      <div className='h-[350px] relative bg-cover bg-center rounded-2xl overflow-hidden cursor-pointer'>
                        <img
                          src={item.images[0]}
                          alt='home'
                          className='rounded-2xl w-full h-full object-cover transform hover:scale-105'
                          style={{
                            transition: 'all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease',
                          }}
                        />
                        <div className='p-4 rounded-b-xl absolute top-[55%] w-full'>
                          <div className='w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm'>
                            <div className='flex justify-start items-center'>
                              <h2 className='text-lg text-[#006938] font-bold whitespace-nowrap'>{formatPrice(item.listPrice)}</h2>
                            </div>
                            <div className='flex gap-1 items-center'>
                              <GrLocation size={20} className="shrink-0" />
                              <h2 className='text-sm overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap' data-tooltip-id="my-tooltip" data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}>{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                              <div className='flex items-center gap-2'>
                                <div className='flex gap-1 items-center'>
                                  <IoBedOutline size={20} />
                                  <h4 data-tooltip-id="my-tooltip" data-tooltip-content={item.details.numBedrooms ? item.details.numBedrooms : 0}>{item.details.numBedrooms ? item.details.numBedrooms : 0}</h4>

                                </div>
                                <span className='text-gray-300'>|</span>
                                <div className='flex gap-1 items-center'>
                                  <LiaBathSolid size={20} />
                                  <h4 data-tooltip-id="my-tooltip" data-tooltip-content={item.details.numBathrooms ? item.details.numBathrooms : 0}>{item.details.numBathrooms ? item.details.numBathrooms : 0}</h4>

                                </div>
                                <span className='text-gray-300'>|</span>
                                <div className='flex gap-1 items-center'>
                                  <BiArea size={20} />
                                  <h4 className='truncate' data-tooltip-id="my-tooltip" data-tooltip-content={item.lot.width ? `${item.lot.depth} x ${item.lot.width}` : '0 x 0'}>{item.lot.width ? `${item.lot.depth} x ${item.lot.width}` : '0 x 0'}</h4>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}

              </div>
              <Tooltip id="my-tooltip" />
            </div>
          </div>
          <RequestTourInfo open={isRequestTourOpen} title={title} handleClose={() => setIsRequestTourOpen(false)} />

        </div>
      )}

    </div>



  );
}

export default PropertyView;
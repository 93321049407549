import React, { useState, useContext, useRef } from 'react';
import { FilterContext } from '../components/FilterContext';
import { CgSortAz } from "react-icons/cg";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import { CiViewList } from "react-icons/ci";

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState('activeListings');
  const { status, setStatus, currentView, setCurrentView, sortType, setSortType } = useContext(FilterContext);
  const [sortToggle, setSortToggle] = useState(false);
  const dropdownRef = useRef(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSortToggle(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const tabs = [
    { id: 'activeListings', label: 'Active Listings' },
    { id: 'soldListings', label: 'Sold' },
    { id: 'openHouse', label: 'Open House' },
  ];
  const sortTypes = [
    { id: 'cheapest', label: 'Price High' },
    { id: 'Highest', label: 'Price Low' },
    { id: 'newest', label: 'Build Age High' },
    { id: 'oldest', label: 'Build Age Low' }

  ]

  const toggletab = (tab) => {
    setActiveTab(tab);
    if (tab === 'activeListings') {
      setStatus('A');
    };
    if (tab === 'soldListings') {
      setStatus('U');
    };
    if (tab === 'delisted') {
      setStatus('D');
    };
    if (tab === 'openHouse') {
      setStatus('O')
    }
  };

  const handleClickOutside = () => {
    setSortToggle(false)
  }


  return (
    <div className="p-4 flex justify-between">
      <div className="flex border-b border-gray-200">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => toggletab(tab.id)}
            className={`py-2 px-4 ${activeTab === tab.id
              ? 'border-b-2 border-Stone-500 text-Stone-700 font-semibold'
              : 'text-gray-500'
              }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className='flex gap-2'>
        <div className='relative' ref={dropdownRef}>
          <div className='px-2 py-2 border border-gray-300 rounded-md flex items-center cursor-pointer ' onClick={() => setSortToggle(!sortToggle)}>
            <CgSortAz size={22} />
            <p className='text-base'>Sort</p>
          </div>
          {sortToggle && (
            <div className='absolute mt-2 p-2 bg-white z-30 w-44 rounded-md shadow-md ' style={{ left: '-70%' }}>
              {sortTypes.map((item, index) => (
                <div key={index}
                onClick={() => {
                  setSortType(item.id);
                  setSortToggle(false);
                }}
                  className={`py-2 px-4 hover:bg-gray-300 cursor-pointer ${sortType === item.id
                    ? 'bg-Stone-500 text-white font-semibold'
                    : 'text-gray-600'
                    }`}>

                  {item.label}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='px-2 py-2 border border-gray-300 rounded-md flex items-center cursor-pointer gap-1' onClick={() => setCurrentView(currentView === 'list' ? 'map' : 'list')}>
          {currentView === 'list' ?
            <div className='flex gap-1'>
              <LiaMapMarkedAltSolid size={22} />
              <p>map</p>
            </div> :
            <div className='flex gap-1'>
              <CiViewList size={22} />
              <p>list</p>
            </div>}
        </div >

      </div>
    </div>
  );
};

export default TabComponent;

import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import throttle from 'lodash.throttle';

const MapBoundsLogger = ({ setPolyganValue }) => {
    const map = useMap();
    const prevBoundsRef = useRef(null);

    useEffect(() => {
        const logBounds = throttle(() => {
            const bounds = map.getBounds();
            const polygon = boundsToPolygon(bounds);

            // Compare current bounds with previous bounds
            if (JSON.stringify(prevBoundsRef.current) !== JSON.stringify(polygon)) {
                setPolyganValue(polygon);
                prevBoundsRef.current = polygon;
                console.log('map bounds:', polygon);
            }
        }, 1000);

        map.on('zoomend', logBounds);
        logBounds();

        return () => {
            map.off('zoomend', logBounds);
        };
    }, [map, setPolyganValue]);

    return null;
};

const boundsToPolygon = (bounds) => {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const nw = bounds.getNorthWest();
    const se = bounds.getSouthEast();

    return [
        [sw.lng, sw.lat],
        [nw.lng, nw.lat],
        [ne.lng, ne.lat],
        [se.lng, se.lat],
        [sw.lng, sw.lat] // Closing the polygon by repeating the first point
    ];
};

export default MapBoundsLogger;
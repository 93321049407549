import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import { FcGoogle } from "react-icons/fc";
import { IoClose } from "react-icons/io5";

const AuthDialog = ({ isOpen, onClose, initialForm = 'login' }) => {
    const [isLogin, setIsLogin] = useState(initialForm === 'login' || initialForm === 'signin');
    useEffect(() => {
        setIsLogin(initialForm === 'login' || initialForm === 'signin');
      }, [initialForm]);

  if (!isOpen) return null;

  const toggleForm = () => setIsLogin(!isLogin);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
        >
          <IoClose />
        </button>
        <div></div>

        <h2 className="text-2xl font-semibold text-gray-800 text-start mb-1">
          {isLogin ? 'Welcome back' : 'Welcome to J&D'}
        </h2>
        <p className="text-start text-gray-500 mb-6">
          {isLogin
            ? 'Enter your credentials and get ready to explore!'
            : 'Create an account to join us!'}
        </p>

        <form>
          {!isLogin && (
            <div className="mb-4 relative">
              <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Your Name"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
              />
            </div>
          )}

          {/* Email Input */}
          <div className="mb-4 relative">
            <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
            />
          </div>

          {/* Password Input */}
          <div className="mb-4 relative">
            <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="password"
              placeholder="Your Password"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
            />
          </div>

          {/* Confirm Password for Sign Up */}
          {!isLogin && (
            <div className="mb-4 relative">
              <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="password"
                placeholder="Confirm Password"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
              />
            </div>
          )}

          {/* Remember Me & Forgot Password */}
          {isLogin && (
            <div className="flex items-center justify-between mb-6">
              <label className="flex items-center text-gray-600">
                <input type="checkbox" className="mr-2 rounded border-gray-300" />
                Remember me
              </label>
              <a href="#" className="text-blue-500 text-sm hover:underline">
                Forgot Password?
              </a>
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 mb-4 font-semibold text-white bg-Stone-600 rounded hover:bg-Stone-700"
          >
            {isLogin ? 'Log in' : 'Sign up'}
          </button>

          {/* Google Login Button (for both Log in and Sign up) */}
          <button
            type="button"
            className="w-full flex items-center justify-center py-2 border border-gray-300 rounded font-semibold text-gray-600 hover:bg-gray-100"
          >
            <FcGoogle className="mr-2" /> {isLogin ? 'Log in with Google' : 'Sign up with Google'}
          </button>
        </form>

        {/* Toggle between Log In and Sign Up */}
        <p className="text-center text-gray-600 mt-6">
          {isLogin ? (
            <>
              Doesn’t have an account?{' '}
              <button onClick={toggleForm} className="text-blue-500 hover:underline">
                Sign up now
              </button>
            </>
          ) : (
            <>
              Already have an account?{' '}
              <button onClick={toggleForm} className="text-blue-500 hover:underline">
                Log in
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default AuthDialog;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import home1 from '../assets/images/home 1.jpg';
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { GiHomeGarage } from "react-icons/gi";
import MarkerClusterGroup from "react-leaflet-cluster";
import 'react-leaflet-markercluster/dist/styles.min.css';
import { getMapListings } from '../service';
import MapBoundsLogger from './MapBoundsLogger'; // Import the MapBoundsLogger component

const MapComponent = ({ propertyType, location, priceRange, minPrice, maxPrice, beds, baths }) => {
    const [Data, setData] = useState([]);
    const [polyganValue, setPolyganValue] = useState([[-79.3928178512883, 43.65790500294517], [-79.40145579410431, 43.651653527879944], [-79.38874165704074, 43.64991005052994], [-79.3928178512883, 43.65790500294517]]);

    const prevValues = useRef({ propertyType, baths, beds, polyganValue });

    const fetchData = useCallback(async () => {
        console.log('poly', polyganValue);
        let filterQuery = JSON.stringify([polyganValue]);

        try {
            console.log('filterQuery', filterQuery);
            const dataList = await getMapListings(filterQuery);
            setData(dataList);
            console.log('filter', dataList);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [propertyType, baths, beds, polyganValue]);

    useEffect(() => {
        fetchData();
    }, [propertyType, baths, beds, polyganValue, fetchData]);

    const createCustomMarkerIcon = (label, price) => {
        return L.divIcon({
            html: `
                <div class="relative flex items-center bg-blue-700 text-white rounded-lg px-2 py-1 shadow-md w-28">
                    <div class="bg-white text-blue-700 font-bold rounded-md px-2 py-1 mr-2">${label}</div>
                    <div class="font-bold text-white">$ ${price}</div>
                    <div class="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-blue-700"></div>
                </div>`,
            iconSize: [50, 50],
            iconAnchor: [25, 50],
        });
    };

    const createClusterCustomIcon = (cluster) => {
        const count = cluster.getChildCount();
        return L.divIcon({
            html: `<div class="flex items-center justify-center bg-blue-700 text-white rounded-full w-12 h-12">${count}</div>`,
            className: 'custom-cluster-icon',
            iconSize: L.point(45, 45, true),
        });
    };

    const formatPrice = (price) => {
        if (price >= 1000000) {
            return (price / 1000000).toFixed(1) + 'M';
        } else if (price >= 1000) {
            return Math.round(price / 1000) + 'K';
        } else {
            return price;
        }
    };

    const formatNumber = (num) => {
        const number = Number(num);
        return number % 1 === 0 ? number.toFixed(0) : number.toFixed(1);
    };

    const AdjustMapPaneZIndex = () => {
        const map = useMap();

        // Adjust zIndex for all panes
        map.getPane('tilePane').style.zIndex = -1000;
        map.getPane('overlayPane').style.zIndex = -1000;
        map.getPane('shadowPane').style.zIndex = -1000;
        map.getPane('markerPane').style.zIndex = -1000;
        map.getPane('tooltipPane').style.zIndex = -1000;
        map.getPane('popupPane').style.zIndex = -1000;

        return null;
    };

    // Calculate mapCorinates inside the render logic
    const mapCorinates = Data.data?.listings ? Data.data.listings.map((item) => ({
        lat: item.map.latitude,
        lng: item.map.longitude,
        propertyPrice: item.listPrice,
        propertyType: item.details.propertyType,
        price: formatPrice(item.listPrice),
        images: item.images,
        mlsNumber: item.mlsNumber,
        title: item.details.propertyType.charAt(0), // Just an example for title
        address: `${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`,
        bathroomsCount: item.details.numBathrooms,
        roomsCount: item.details.numBedrooms,
        garage: item.details.numGarageSpaces || item.details.numParkingSpaces !== null
            ? `${formatNumber(item.details.numParkingSpaces)} + ${formatNumber(item.details.numGarageSpaces)}`
            : 0,
    })) : [];

    const navigate = useNavigate();

    return (
        <MapContainer
            center={[44.2266207, -79.4653374]}
            zoom={15}
            style={{ height: '100%', width: '100%' }}
        >
            <TileLayer
                zIndex={-1000}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapBoundsLogger setPolyganValue={setPolyganValue} /> {/* Pass setPolyganValue as a prop */}
            <AdjustMapPaneZIndex />
            <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
                {mapCorinates.map((position, index) => (
                    <Marker key={index} position={[position.lat, position.lng]} icon={createCustomMarkerIcon(position.title, position.price)}>
                        <Popup className='leafletPopup'>
                            <div className="flex flex-row w-96 gap-2 border border-gray-300 rounded-xl p-2 font-open-sans cursor-pointer" onClick={() => navigate(`/property/${position.mlsNumber}`)}>
                                <img src={position.images[0]} alt="home" className="w-28 h-28 object-cover rounded-lg" />
                                <div className="flex flex-col gap-2">
                                    <h3 className="text-base font-bold">$ {position.propertyPrice}</h3>
                                    <p className='text-xs mr-0'>{position.address}</p>
                                    <div className='flex items-center divide-x-2 gap-3'>
                                        <div className='flex items-center gap-1'>
                                            <IoBedOutline />
                                            <span>{position.roomsCount}</span>
                                        </div>
                                        <div className='flex items-center gap-1 pl-2'>
                                            <LiaBathSolid />
                                            <span>{position.bathroomsCount}</span>
                                        </div>
                                        <div className='flex items-center gap-1 pl-2'>
                                            <GiHomeGarage />
                                            <span>{position.garage}</span>
                                        </div>
                                    </div>
                                    <p>{position.propertyType}</p>
                                </div>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MarkerClusterGroup>
        </MapContainer>
    );
};

export default MapComponent;
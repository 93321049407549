import React, { useEffect, useState } from 'react';
import video from '../assets/vedios/WpResidence-Theme-Demo-Site-–-WpResidence-Theme-Demo-Site (1).webm';
import mobileVideo from '../assets/vedios/mobile.mp4';
import search from '../assets/images/search (1).png'
import vedioImg from '../assets/images/vedio_img.png'

const BannerVideo = () => {

    const [isVideoLoaded, setVideoLoaded] = useState(false);

    return (
        <>
            <div className='w-full h-2/5 relative'>

                {!isVideoLoaded && <img src={vedioImg} alt="Loading..." className='max-sm:hidden bg-black/70' />}
                <video src={video} autoPlay
                    muted
                    preload="auto"
                    loop
                    onLoadedData={() => setVideoLoaded(true)}
                    style={{ display: isVideoLoaded ? 'block' : 'none' }} 
                    className='max-sm:hidden bg-black/70'></video>

                <video src={mobileVideo} autoPlay loop muted className='xl:hidden'></video>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className='absolute inset-0 flex flex-col justify-center items-center'>
                    <h1 className='text-[65px] leading-[69px] font-bold text-white max-sm:text-2xl'>Luxury Real Estate in <br />Toronto</h1>
                    <div className='p-5 font-open-sans flex mt-7 ml-16 bg-white rounded-lg justify-between pr-5 w-[500px] pl-10 max-sm:p-2 max-sm:w-auto max-sm:mx-0'>
                        <input
                            className='placeholder:text-gray-500 w-full placeholder:text-sm max-sm:placeholder:text-sm outline-none'
                            placeholder="Search by Area, City, PostalCode or MLS® number"
                        />
                        <img src={search} alt='search' width={25} />
                    </div>
                </div>
            </div>

        </>
    )
};

export default BannerVideo;